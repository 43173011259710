/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Modal, Button, DatePicker, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { PermissibleRender } from "@brainhubeu/react-permissible";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import ds from "../../Services/DataService";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const SidebarContent = () => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  var storedPolicies;

  const [logModal, setLogModal] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [fromDate, setFromDate] = useState("2020-11-01");
  const [toDate, setToDate] = useState(moment().local().format("YYYY-MM-DD"));

  useEffect(() => {
    storedPolicies = JSON.parse(localStorage.getItem("userPolicies"));
  }, []);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const dateChange = (value) => {
    setFromDate(moment(value[0]).local().format("YYYY-MM-DD"));
    setToDate(moment(value[1]).local().format("YYYY-MM-DD"));
  };

  const generateLogsReport = () => {
    let body = {
      from: fromDate,
      to: toDate,
      logGroupName: "public-api",
      limit: "1000",
    };

    setGeneratingReport(true);
    ds.generateLogsReport(body)
      .then((res) => {
        console.log(
          "🚀 ~ file: listing.js ~ line 137 ~ TranxListing ~ .then ~ res",
          res
        );
        setLogModal(false);
        setGeneratingReport(false);
        window.open(res.data.result, "_blank");
        // message.success("Report downloaded");
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: SidebarContent.js ~ line 79 ~ generateLogsReport ~ err",
          err
        );
        setLogModal(false);
        setGeneratingReport(false);
        // message.error("Something error while downloading");
      });
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const SubMenu = Menu.SubMenu;

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {/* <Menu.Item key="dashboard/main">
              <Link to="/dashboard/main">
                <i className="icon icon-widgets" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item> */}

            <Menu.ItemGroup key="cex" title="Customers">
              <Menu.Item key="users">
                <Link to="/users">
                  <i className="icon icon-family" />
                  <span>
                    <IntlMessages id="sidebar.users" />
                  </span>
                </Link>
              </Menu.Item>
              <>
                <Menu.Item key="kyc">
                  <Link to="/kyc">
                    <i className="icon icon-auth-screen" />
                    <span>
                      <IntlMessages id="sidebar.kyc" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="banks">
                  <Link to="/banks">
                    <i className="icon icon-company" />
                    <IntlMessages id="sidebar.banks" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="dispute">
                  <Link to="/disputes">
                    <i className="icon icon-exclamation" />
                    <IntlMessages id="sidebar.disputes" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="whitelist">
                  <Link to="/whitelist">
                    <i className="icon icon-icon" />
                    <IntlMessages id="sidebar.fexWhiteList" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="requests">
                  <Link to="/requests">
                    <i className="icon icon-user" />
                    <IntlMessages id="sidebar.adminRequest" />
                  </Link>
                </Menu.Item>
              </>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="Finance" title="Finance">
              <Menu.Item key="transactions/deposit">
                <Link to="/transactions/fiat/deposit/listing">
                  <i className="icon icon-orders" />
                  <IntlMessages id="sidebar.deposit" />
                </Link>
              </Menu.Item>
              <Menu.Item key="transactions/withdrawal">
                <Link to="/transactions/fiat/withdrawal/listing">
                  <i className="icon icon-shuffle" />
                  <IntlMessages id="sidebar.withdrawal" />
                </Link>
              </Menu.Item>
              <Menu.Item key="card">
                <Link to="/transactions/card-deposits">
                  <i className="icon icon-card" />
                  Card Deposits
                </Link>
              </Menu.Item>
              <Menu.Item key="ledger">
                <Link to="/transactions/ledger">
                  <i className="icon icon-editor" />
                  Profit Ledger
                </Link>
              </Menu.Item>
              <Menu.Item key="crypto">
                <Link to="/transactions/crypto/listing">
                  <i className="icon icon-transfer" />
                  <IntlMessages id="sidebar.cryptoTransaction" />
                </Link>
              </Menu.Item>
              <Menu.Item key="cryptoOrders">
                <Link to="/transactions/crypto/orders">
                  <i className="icon icon-timeline-with-icons" />
                  <IntlMessages id="sidebar.cryptoOrders" />
                </Link>
              </Menu.Item>
              <Menu.Item key="trades">
                <Link to="/trades">
                  <i className="icon icon-link" />
                  User's Trades
                </Link>
              </Menu.Item>
              <Menu.Item key="reimbursements">
                <Link to="/reimbursements">
                  <i className="icon icon-modal" />
                  Reimbursements
                </Link>
              </Menu.Item>
              <Menu.Item key="referrals">
                <Link to="/referrals">
                  <i className="icon icon-visits" />
                  Referrals
                </Link>
              </Menu.Item>
              <Menu.Item key="dispute">
                <Link to="/disputes">
                  <i className="icon icon-exclamation" />
                  Dispute Management
                </Link>
              </Menu.Item>
              <Menu.Item key="complyAdvantages">
                <Link to="/comply-advantages">
                  <i className="icon icon-stats" />
                  Comply Advantage
                </Link>
              </Menu.Item>
              <Menu.Item key="activities">
                <Link to="/activities">
                  <i className="icon icon-visits" />
                  Admin Activities
                </Link>
              </Menu.Item>
              <Menu.Item key="donations">
                <Link to="/donations">
                  <i className="icon icon-visits" />
                  Donations
                </Link>
              </Menu.Item>
              <Menu.Item key="CryptpBundles">
                <Link to="/crypto-bundles">
                  <i className="icon icon-calendar" />
                  <span>
                    <IntlMessages id="sidebar.bundles" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="Gift Vouchers">
                <Link to="/vouchers">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.giftVouchers" />
                  </span>
                </Link>
              </Menu.Item>
              {/* {localStorage.role === "superAdmin" && ( */}
                <Menu.Item key="Rewards">
                  <Link to="/rewards">
                    <i className="icon icon-revenue-new" />
                    <span>
                      <IntlMessages id="sidebar.rewards" />
                    </span>
                  </Link>
                </Menu.Item>
              {/* )} */}
              <Menu.Item key="Fees and Taxes">
                <Link to="/fees">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.fees" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="leadSeaction" title="Leads">
              <Menu.Item key="leads">
                <Link to="/leads">
                  <i className="icon icon-social" />
                  <span>List Leads</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="translationSection" title="Translation">
              <Menu.Item key="translations">
                <Link to="/translations">
                  <i className="icon icon-social" />
                  <span>Translation Files</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="marketingSection" title="Marketing">
              <Menu.Item key="appBanner">
                <Link to="/banners">
                  <i className="icon icon-icon" />
                  <IntlMessages id="sidebar.marketingBanners" />
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="cex" title="Social Feed">
              <Menu.Item key="posts">
                <Link to="/posts">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.posts" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="comments">
                <Link to="/comments">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.comments" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="announcements">
                <Link to="/social-announcement">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.announcements" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="cex" title="Education">
              <Menu.Item key="courses">
                <Link to="/courses">
                  <i className="icon icon-family" />
                  <span>
                    <IntlMessages id="sidebar.courses" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="cex" title="Testing Users">
              <Menu.Item key="phonenumbers">
                <Link to="/phonenumbers">
                  <i className="icon icon-family" />
                  <span>
                    <IntlMessages id="sidebar.phonenumbers" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="emails">
                <Link to="/emails">
                  <i className="icon icon-family" />
                  <span>
                    <IntlMessages id="sidebar.emails" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="cex" title="OTP">
              <Menu.Item key="otp">
                <Link to="/otp">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.opt" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="cex" title="OTP">
              <Menu.Item key="otp">
                <Link to="/otp">
                  <i className="icon icon-social" />
                  <span>
                    <IntlMessages id="sidebar.opt" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <React.Fragment>
              <Menu.ItemGroup
                key="superAdminMenuGroup"
                title="Back Office (Only for Administrator)"
              >
                <Menu.Item key="maintenance">
                  <Link to="/maintenance">
                    <i className="icon icon-social" />
                    <span>Maintenance Announcement</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="logs">
                  <li
                    onClick={() => {
                      setLogModal(true);
                    }}
                  >
                    <i className="icon icon-notification-new" />
                    Cloud Watch Logs
                  </li>
                </Menu.Item>
                <Menu.Item key="coins">
                  <Link to="/coins">
                    <i className="icon icon-crypto" />
                    <span>
                      <IntlMessages id="sidebar.coins" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="emailTemplates">
                  <Link to="/email-templates">
                    <i className="icon icon-mail-open" />
                    Email Templates
                  </Link>
                </Menu.Item>
                <Menu.Item key="campaign">
                  <Link to="/campaign">
                    <i className="icon icon-calendar" />
                    <span>
                      <IntlMessages id="sidebar.campaign" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="CryptpBundles">
                  <Link to="/crypto-bundles">
                    <i className="icon icon-calendar" />
                    <span>
                      <IntlMessages id="sidebar.bundles" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="countryWhiteList">
                  <Link to="/country-feature-control">
                    <i className="icon icon-calendar" />
                    <span>
                      <IntlMessages id="sidebar.countryWhiteList" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="announcement">
                  <Link to="/announcement">
                    <i className="icon icon-megaphone" />
                    <span>
                      <IntlMessages id="sidebar.announcement" />
                    </span>
                  </Link>
                </Menu.Item>

                {/*<Menu.Item key="subAccounts">
                  <Link to="/sub-accounts">
                    <i className="icon icon-tasks" />
                    Sub Accounts
                  </Link>
                </Menu.Item>*/}
                <Menu.Item key="limitOrders">
                  <Link to="/limit-orders">
                    <i className="icon icon-anchor" />
                    Limit Orders
                  </Link>
                </Menu.Item>
                <Menu.Item key="subadmin">
                  <Link to="/sub-admin">
                    <i className="icon icon-signup" />
                    <span>
                      <IntlMessages id="sidebar.sub.admin" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="IAM">
                  <Link to="/iam">
                    <i className="icon icon-icon" />
                    IAM
                  </Link>
                </Menu.Item>
                <Menu.Item key="activities">
                  <Link to="/activities">
                    <i className="icon icon-visits" />
                    Admin Activities
                  </Link>
                </Menu.Item>
                <Menu.Item key="redis">
                  <Link to="/redis">
                    <i className="icon icon-callout" />
                    Redis Explorer
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup key="cex" title="Staking">
                <Menu.Item key="staking">
                  <Link to="/staking-stats">
                    <i className="icon icon-calendar" />
                    General
                  </Link>
                </Menu.Item>
                <Menu.Item key="providers">
                  <Link to="/providers">
                    <i className="icon icon-callout" />
                    Providers
                  </Link>
                </Menu.Item>
                <Menu.Item key="protocols">
                  <Link to="/protocols">
                    <i className="icon icon-anchor" />
                    Protocols
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Liquidity Monitoring">
                <Menu.Item key="liquidityMonitoring">
                  <Link to="/liquidity-monitoring">
                    <i className="icon icon-calendar" />
                    General
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Savers">
                <Menu.Item key="savers">
                  <Link to="/savers">
                    <i className="icon icon-calendar" />
                    General
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Partners">
                <Menu.Item key="savers">
                  <Link to="/partners">
                    <i className="icon icon-calendar" />
                    General
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </React.Fragment>
          </Menu>
        </CustomScrollbars>
      </div>
      <Modal
        title={"Download Cloud Watch Logs"}
        centered
        visible={logModal}
        onCancel={() => setLogModal(false)}
        footer={[
          <Button
            key="1"
            type="danger"
            onClick={() => {
              setLogModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="2"
            type="primary"
            disabled={generatingReport}
            onClick={() => {
              generateLogsReport();
            }}
          >
            Generate Report
          </Button>,
        ]}
      >
        <Title level={5}>Select Date Range</Title>
        <Space direction="vertical" size={12}>
          <RangePicker
            defaultValue={[moment(), moment(fromDate, "YYYY-MM-DD")]}
            onChange={(value) => dateChange(value)}
          />
        </Space>
      </Modal>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
