import React, { useState, useEffect } from "react";
import {
  Avatar,
  Popover,
  Modal,
  Alert,
  Button,
  Form,
  Input,
  message,
  notification,
} from "antd";
import { FieldBinaryOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ds from "../../Services/DataService";
import PinCode from "react-pin-code-input-component";

const UserProfile = () => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          setModal({ changePassModal: true });
        }}
      >
        Change Password
      </li>
      <li
        onClick={() => {
          setModal({ mfaModal: true });
        }}
      >
        Google Auth
      </li>
      <li
        onClick={() => {
          localStorage.removeItem("adminToken");
          window.location.href = "/signin";
        }}
      >
        Logout
      </li>
    </ul>
  );

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          setModal({ mfaModal: true });
        }}
      >
        Next
      </Button>
    );
    notification["warning"]({
      message: "Please Enable 2FA",
      description: "Enable Google Authenticator to secure your account!",
      btn,
      key,
      duration: 3,
      onClose: close,
    });
  };

  const close = () => {
    console.log(
      "Notification was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  // let checkMfa = localStorage.isGAuthEnabled === "1" ? false : true;
  const [modal, setModal] = useState({
    notice: false,
    mfaModal: false, //checkMfa,
    verifyGaut: false, //false,
    confirm: false,
    changePassModal: false,
  });
  const [gauth, setGauth] = useState({ qrcode: "", gcode: "" });
  const [otp, setOtp] = useState(0);
  const [password, setPassword] = useState();

  useEffect(() => {
    let checkMfa = localStorage.isGAuthEnabled === "1" ? false : true;
    if (checkMfa) {
      openNotification();
    }

    ds.qrCode().then((res) => {
      setGauth({ qrcode: res.data.result.qrcode, gcode: res.data.result.code });
    });
  }, []);

  function setupGauth() {
    if (otp.length === 6) {
      ds.setupGauth({ gAuthCode: otp, loginPassword: password })
        .then((res) => {
          setModal({ confirm: true });
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    } else {
      message.error("Please enter 6 digits OTP code and existing password");
    }
  }

  const FormItem = Form.Item;

  const [loading, setLoading] = React.useState(false);

  const key = "updatable";

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let chPass = await ds
      .changePassword({
        password: values.password,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      })
      .catch((err) => {
        if (err) {
          message.error({
            content: err.response.data.message,
            key,
            duration: 2,
          });
        }
      });
    setLoading(false);
    if (chPass) {
      setModal({ changePassModal: false });
      message.success({
        content: "Password updated successfully",
        key,
        duration: 2,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar
            src="https://via.placeholder.com/150x150"
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {localStorage.name}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
      <Modal
        title="Please enable 2FA"
        centered
        visible={modal.notice}
        onOk={() => setModal({ notice: false })}
        onCancel={() => setModal({ notice: false })}
        footer={false}
      ></Modal>
      <Modal
        title=""
        centered
        visible={modal.mfaModal}
        onOk={() => setModal({ mfaModal: false })}
        onCancel={() => setModal({ mfaModal: false })}
        footer={false}
      >
        <div className="gx-text-center gx-pt-3">
          <h3>Scan this QR code in the Google Authenticator app</h3>
          Download app for&nbsp;
          <a
            target="_BLANK"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          >
            iPhone
          </a>
          &nbsp;or&nbsp;
          <a
            target="_BLANK"
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          >
            Android
          </a>
        </div>
        <div className="gx-d-flex">
          <div className="gx-col-5">
            <img src={gauth.qrcode} />
          </div>
          <div className="gx-col gx-mt-4">
            <p>
              If you are unable to scan the QR code, please enter this code
              manually into the app.
            </p>
            <Alert message={gauth.gcode} type="info" />
          </div>
        </div>
        <div className="gx-d-flex gx-mt-2">
          <div className="gx-col">
            <Button
              onClick={() => {
                setModal({ mfaModal: false });
              }}
              block
            >
              {" "}
              Skip{" "}
            </Button>
          </div>
          <div className="gx-col">
            <Button
              onClick={() => {
                setModal({ verifyGaut: true });
              }}
              type="primary"
              block
            >
              {" "}
              Next{" "}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={modal.verifyGaut}
        onOk={() => setModal({ verifyGaut: false })}
        onCancel={() => setModal({ verifyGaut: false })}
        footer={false}
      >
        <div className="gx-text-center gx-mb-3">
          <h3>Google Authenticator Code</h3>
        </div>
        {/* <div className="gx-d-flex">
          <div className="gx-col"> */}
        <div className="gx-d-flex gx-pt-2">
          <label className="gx-pl-2">Enter Google Authenticator Code</label>
        </div>

        <div className="gx-d-flex gx-pt-2 gx-pr-20">
          <PinCode
            className="fex-pincode"
            type="text"
            fields="6"
            onChange={(value) => {
              setOtp(value);
            }}
          />
        </div>

        <div className="gx-d-flex gx-pt-2 gx-pb-1 gx-pr-2 gx-pl-2">
          <FormItem
            className="gx-pl-3 gx-mt-4"
            rules={[
              {
                required: true,
                message: "Please enter your existing password",
              },
            ]}
            name="password"
          >
            <label>Enter your password</label>
            <Input
              className="gx-mt-2"
              style={{ width: "455px" }}
              placeholder="Confirm password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormItem>
        </div>

        {/* </div>
        </div> */}
        <div className="gx-d-flex gx-mt-2">
          <div className="gx-col">
            <Button
              onClick={() => {
                setModal({ mfaModal: true });
              }}
              block
            >
              {" "}
              Back{" "}
            </Button>
          </div>
          <div className="gx-col">
            <Button
              onClick={() => {
                setupGauth();
              }}
              type="primary"
              block
            >
              {" "}
              Setup{" "}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={modal.confirm}
        onOk={() => setModal({ confirm: false })}
        onCancel={() => setModal({ confirm: false })}
        footer={false}
      >
        <div className="gx-text-center gx-mt-4">
          <h3>Google Authenticator Setup is Now Completed</h3>
        </div>
        <div className="gx-d-flex gx-mt-4">
          <div className="gx-col">
            <Button
              onClick={() => {
                setModal({ confirm: false });
              }}
              type="primary"
              block
            >
              {" "}
              Close{" "}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={modal.changePassModal}
        onOk={() => setModal({ changePassModal: false })}
        onCancel={() => setModal({ changePassModal: false })}
        footer={false}
      >
        <div className="gx-text-center gx-pt-3">
          <h3>Change Password</h3>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[
              {
                required: true,
                message: "Please enter your existing password",
              },
            ]}
            name="password"
          >
            <Input
              prefix={
                <FieldBinaryOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Old Password"
            />
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please enter your new passsword" },
            ]}
            name="newPassword"
          >
            <Input
              prefix={
                <FieldBinaryOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="New Password"
            />
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please comfirm your password" },
            ]}
            name="confirmPassword"
          >
            <Input
              prefix={
                <FieldBinaryOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Confirm Password"
            />
          </FormItem>

          <FormItem className="gx-text-center">
            <Button loading={loading} type="primary" htmlType="submit">
              Change Password
            </Button>
          </FormItem>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserProfile;
