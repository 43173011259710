import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import InfoView from 'components/InfoView';
import DataService from '../Services/DataService';
import PinCode from 'react-pin-code-input-component';

const SignIn = (props) => {
  const token = useSelector(({ auth }) => auth.token);
  const [email, setEmail] = useState();

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const [otp, setOtp] = useState(0);
  const [accessToken, setAccessToken] = useState();
  const [modal, setModal] = useState({ verifyGauth: false });

  const onFinish = (values) => {
    // dispatch(userSignIn(values));
    setEmail(values.email);
    DataService.doLogin({ email: values.email, password: values.password })
      .then((res) => {
        localStorage.setItem('isGAuthEnabled', res.data.result.isGAuthEnabled);
        if (res.data.result === false) {
          message.error('Please make sure you are conencted via VPN');
        }
        if (res.data.result.accessToken) {
          setAccessToken(res.data.result.accessToken);
          localStorage.setItem('userId', res.data.result.id);
          localStorage.setItem('name', res.data.result.name);
          localStorage.setItem('role', res.data.result.role);

          DataService.getUserAllPolicies(
            res.data.result.id,
            res.data.result.accessToken,
          ).then(
            (response) => {
              let userPolicies = [];
              if (response.data.result.length > 0) {
                for (let i = 0; i < response.data.result.length; i++) {
                  userPolicies.push(response.data.result[i].name);
                }
                console.log('🚀 ~  ~ ~ ~ ', userPolicies);
              }

              localStorage.setItem(
                'userPolicies',
                JSON.stringify(userPolicies),
              );

              // localStorage.setItem("userPolicies", userPolicies);

              if (res.data.result.isGAuthEnabled) {
                setModal({ verifyGauth: true });
              } else {
                localStorage.setItem('adminToken', res.data.result.accessToken);
                window.location.href = '/';
              }
            },
            (err) => {
              console.log('🚀 ~ file: SignIn.js ~ line 53 ~ .then ~ err', err);
            },
          );
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);
  function verifyOtp() {
    if (otp.length === 6) {
      DataService.verifyGauthOtp({ email: email, otpCode: otp })
        .then((res) => {
          localStorage.setItem('adminToken', accessToken);
          window.location.href = '/';
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    } else {
      message.error('Please enter 6 digits OTP');
    }
  }
  return (
    <div className='gx-app-login-wrap'>
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo'>
              <img alt='example' src={require('assets/images/logo.svg')} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            <Form
              initialValues={{ remember: true }}
              name='basic'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className='gx-signin-form gx-form-row0'
            >
              <Form.Item
                initialValue=''
                rules={[
                  { required: true, message: 'The input is not valid E-mail!' },
                ]}
                name='email'
              >
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item
                initialValue=''
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
                name='password'
              >
                <Input type='password' placeholder='Password' />
              </Form.Item>

              <Form.Item>
                <Button type='primary' className='gx-mb-0' htmlType='submit'>
                  <IntlMessages id='app.userAuth.signIn' />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
      <Modal
        title=''
        centered
        visible={modal.verifyGauth}
        onOk={() => setModal({ verifyGauth: false })}
        onCancel={() => setModal({ verifyGauth: false })}
        footer={false}
      >
        <div className='gx-text-center gx-mb-3'>
          <h3>Confirm Your Login Via Google Auth</h3>
        </div>
        <div className='gx-d-flex'>
          <div className='gx-col'>
            <label className='gx-pl-2'>Enter Google Authenticator Code</label>
            <PinCode
              className='fex-pincode'
              type='text'
              fields='6'
              onChange={(value) => {
                setOtp(value);
              }}
            />
          </div>
        </div>
        <div className='gx-d-flex gx-mt-4'>
          <div className='gx-col'>
            <Button
              onClick={() => {
                verifyOtp();
              }}
              type='primary'
              block
            >
              {' '}
              Confirm{' '}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignIn;
